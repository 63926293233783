import Image from "next/image";
import styled from "styled-components";
import { devices } from "@/constants/devices";
import { LinkNoPrefetch } from "../LinkNoPrefetch/LinkNoPrefetch";

export const AuthorStyle = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 16px;
  padding-left: 1rem;

  @media screen and ${devices.tablet} {
    padding: 0;
  }
`;

export const StyledLink = styled(LinkNoPrefetch)`
  text-decoration: none;
  cursor: pointer;
`;

export const ProfileImage = styled(Image)`
  border-radius: 50%;
  object-fit: cover;
`;

export const Name = styled.p`
  margin: 0;
  font-size: 0.8125rem;
  font-style: normal;
  font-weight: 700;

  span {
    font-weight: normal;
    color: ${({ theme }) => theme.author.secondaryColor};
  }
`;

export const TextContainer = styled.div`
  margin-left: 20px;
`;

export const NamesRow = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 3px;
`;

export const TwitterContainer = styled.div`
  display: flex;
  align-items: center;

  span {
    padding-right: 10px;
    font-style: normal;
    color: ${({ theme }) => theme.author.secondaryColor};
    font-size: 0.8rem;
    display: inherit;
    -webkit-box-align: center;
    align-items: center;
    margin: 0;
    font-weight: 500;
  }
`;

export const Time = styled.time`
  font-style: normal;
  color: ${({ theme }) => theme.author.secondaryColor};
  font-size: 0.8rem;
  line-height: 1.5;
  display: inherit;
  -webkit-box-align: center;
  font-weight: 500;
`;

export const AuthorContainer = styled.div`
  text-decoration: none;
  color: ${({ theme }) => theme.author.fontColor};
`;
