import { unixTimestampMsToDayDateMonthYearHourMinAndSeconds } from "@/lib/date";
import { AuthorWidget } from "@/types/api/content";
import { getActiveBrand } from "@/lib/brand";
import {
  AuthorStyle,
  Name,
  TextContainer,
  NamesRow,
  TwitterContainer,
  AuthorContainer,
  Time,
  ProfileImage,
  StyledLink,
} from "@/components/Author/Author.style";
import { AuthorData } from "@/types/api/authors";
import { XLogo } from "@/components/icons/XLogo";
import { useDarkMode } from "@/hooks/useDarkMode";

interface Props {
  activeAuthor: AuthorWidget | AuthorData;
  contentLastUpdated: number;
}

function Author({ activeAuthor, contentLastUpdated }: Props): JSX.Element {
  const brand = getActiveBrand();
  const [colourMode] = useDarkMode();

  const authorRoute = `/author/${activeAuthor?.slug}`;
  const imageSrc = activeAuthor?.profile_image
    ? `https://cdn.checkd.media/images/${activeAuthor?.profile_image}-sm.jpg`
    : `https://cdn.checkd.media/menu/${brand}/homepage.svg`;
  const dateString =
    unixTimestampMsToDayDateMonthYearHourMinAndSeconds(contentLastUpdated);
  const xLogoFill = colourMode === "dark" ? "#fff" : "#000";

  return (
    <AuthorStyle key={activeAuthor?.slug}>
      <StyledLink href={authorRoute}>
        <ProfileImage
          src={imageSrc}
          alt={activeAuthor?.first_name}
          width={50}
          height={50}
        />
      </StyledLink>

      <TextContainer>
        <NamesRow>
          <StyledLink href={authorRoute}>
            <AuthorContainer>
              <Name>
                {activeAuthor?.first_name} {activeAuthor?.surname}
                <span> |</span>
              </Name>
            </AuthorContainer>
          </StyledLink>

          {activeAuthor?.twitter_handle ? (
            <StyledLink
              href={`https://x.com/${activeAuthor?.twitter_handle}`}
              target="_blank"
              rel="noopener noreferrer author"
            >
              <TwitterContainer>
                <span>@{activeAuthor?.twitter_handle} </span>
                <XLogo colorFill={xLogoFill} />
              </TwitterContainer>
            </StyledLink>
          ) : null}
        </NamesRow>
        <Time suppressHydrationWarning>{dateString}</Time>
      </TextContainer>
    </AuthorStyle>
  );
}

export default Author;
